import { IComponentDefinition, LazyInitialization } from 'coveo-search-ui';

export function lazyCoveoForSitecoreRankingExpression(): void {
    LazyInitialization.registerLazyComponent('ForSitecoreRankingExpression', () => {
        return new Promise((resolve, reject) => {
            require.ensure(
                ['./CoveoForSitecoreRankingExpression'],
                () => {
                    const loaded = require<IComponentDefinition>('./CoveoForSitecoreRankingExpression.ts')[
                        'CoveoForSitecoreRankingExpression'
                    ];
                    resolve(loaded);
                },
                LazyInitialization.buildErrorCallback('CoveoForSitecoreRankingExpression', resolve),
                'CoveoForSitecoreRankingExpression'
            );
        });
    });
}
