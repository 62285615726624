import { IComponentDefinition, LazyInitialization } from 'coveo-search-ui';

export function lazyCoveoNavigatorPositionProvider(): void {
    LazyInitialization.registerLazyComponent('NavigatorPositionProvider', () => {
        return new Promise((resolve, reject) => {
            require.ensure(
                ['./NavigatorPositionProvider'],
                () => {
                    const loaded = require<IComponentDefinition>('./NavigatorPositionProvider.ts')[
                        'NavigatorPositionProvider'
                    ];
                    resolve(loaded);
                },
                LazyInitialization.buildErrorCallback('NavigatorPositionProvider', resolve),
                'NavigatorPositionProvider'
            );
        });
    });
}
