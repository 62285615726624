import { ExternalComponentInitializer } from './initialization/ExternalComponentInitializer';
import { SearchboxInitializer } from './initialization/SearchboxInitializer';
import { SearchInterfaceInitializer } from './initialization/SearchInterfaceInitializer';
import { ElementWithContextInitializer } from './initialization/ElementWithContextInitializer';
import { HostedSearchPageInitializer } from './initialization/HostedSearchPageInitializer';

export const searchInterfaceInitializer = new SearchInterfaceInitializer();
export const searchboxInitializer = new SearchboxInitializer();
export const externalComponentInitializer = new ExternalComponentInitializer();
export const hostedSearchPageInitializer = new HostedSearchPageInitializer();

export function initSearchInterface(searchInterfaceElement: HTMLElement): Promise<{ elem: HTMLElement }> {
    return searchInterfaceInitializer.initSearchInterface(searchInterfaceElement);
}

export function initializeSearchInterfaceForExperienceEditor(
    searchInterfaceElement: HTMLElement
): Promise<{ elem: HTMLElement }> {
    searchInterfaceInitializer.initializeExperienceEditorHooksForSearchInterface(searchInterfaceElement);
    return initSearchInterface(searchInterfaceElement);
}

export function initSearchboxIfStandalone(
    searchBoxElement: HTMLElement,
    searchPageUri: string,
    options?: any
): Promise<void> {
    return searchboxInitializer.initSearchboxIfStandalone(searchBoxElement, searchPageUri, options);
}

export function initContextOnElement(element: HTMLElement): void {
    ElementWithContextInitializer.initializeContextWithinElement(element);
}

export function initExternalComponentsSection(element: HTMLElement): void {
    externalComponentInitializer.initializeExternalComponentSection(element);
}

export function initExternalComponentsSectionForExperienceEditor(element: HTMLElement): void {
    externalComponentInitializer.initializeExperienceEditorHooksForExternalComponentsSection(element);
    initExternalComponentsSection(element);
}

export function initHostedSearchPageInitializer(element: HTMLElement): void {
    hostedSearchPageInitializer.initializeSearchPage(element);
}
