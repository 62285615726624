(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("coveo-search-ui"));
	else if(typeof define === 'function' && define.amd)
		define(["coveo-search-ui"], factory);
	else if(typeof exports === 'object')
		exports["CoveoForSitecore"] = factory(require("coveo-search-ui"));
	else
		root["CoveoForSitecore"] = factory(root["Coveo"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__) {
return 