import { IComponentDefinition, LazyInitialization } from 'coveo-search-ui';

export function lazyCoveoStaticPositionProvider(): void {
    LazyInitialization.registerLazyComponent('StaticPositionProvider', () => {
        return new Promise((resolve, reject) => {
            require.ensure(
                ['./StaticPositionProvider'],
                () => {
                    const loaded = require<IComponentDefinition>('./StaticPositionProvider.ts')[
                        'StaticPositionProvider'
                    ];
                    resolve(loaded);
                },
                LazyInitialization.buildErrorCallback('StaticPositionProvider', resolve),
                'StaticPositionProvider'
            );
        });
    });
}
