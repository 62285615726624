import { Prebinding } from '../../base/Prebinding';
import { nodeListToArray } from '../../utils/HTMLUtils';

import { Initialization, SearchInterface, get, Facet, Tab } from 'coveo-search-ui';

const duringInitializationClass = 'coveo-during-initialization';

export class SearchInterfaceComponentInitializer implements ISearchInterfaceComponentInitializer {
    public loadComponentsForSearchInterface(
        searchInterface: SearchInterface,
        newComponent: HTMLElement
    ): Promise<void> {
        Prebinding.applyPrebindingOnElement(newComponent);
        Prebinding.applyPrebindingOnChildren(newComponent);
        const result = Initialization.automaticallyCreateComponentsInside(newComponent, {
            bindings: searchInterface.getBindings(),
            options: {},
        });
        return result.initResult.then(() => {
            this.removeAllPlaceholdersWithinElement(newComponent);
            searchInterface.queryController.executeQuery({
                ignoreWarningSearchEvent: true,
            });
        });
    }

    public tryUnloadComponentsForSearchInterface(searchInterface: SearchInterface, newComponent: HTMLElement): void {
        const facets = nodeListToArray(newComponent.getElementsByClassName('CoveoFacet'));
        facets.forEach((facet) => {
            const facetComponent: Facet = get(facet, 'CoveoFacet') as Facet;
            if (facetComponent) {
                searchInterface.detachComponent('Facet', facetComponent);
            }
            this.removeAllChildren(facet);
        });

        const tabs = nodeListToArray(newComponent.getElementsByClassName('CoveoTab'));
        tabs.forEach((tab) => {
            const tabComponent: Tab = get(tab, 'CoveoTab') as Tab;
            if (tabComponent) {
                searchInterface.detachComponent('Tab', tabComponent);
            }
            this.removeAllChildren(tab);
        });
    }

    private removeAllPlaceholdersWithinElement(element: HTMLElement): void {
        const elementsWithInitializationClass = nodeListToArray(
            element.getElementsByClassName(duringInitializationClass)
        );
        elementsWithInitializationClass.forEach((element) => this.removePlaceholders(element));
    }

    private removePlaceholders(element: HTMLElement): void {
        element.classList.remove(duringInitializationClass);

        if (element.classList.contains('CoveoFacet')) {
            this.removeFacetsPlaceholders(element);
        }
    }
    private removeFacetsPlaceholders(element: HTMLElement): void {
        element.classList.remove('coveo-with-placeholder');

        // Since a Facet reinserts DOM as children when initializing, we have to remove all its children,
        // else new content will be appended each time the facet is initialized.
        this.removeAllChildren(element);
    }

    private removeAllChildren(element: HTMLElement): void {
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }
    }

    public loadComponentsWithSearchInterfaceInitialization(
        searchInterfaceElement: HTMLElement,
        componentsContainer: HTMLElement
    ): void {
        Prebinding.applyPrebindingOnElement(componentsContainer);
        Prebinding.applyPrebindingOnChildren(componentsContainer);
        Coveo.options(searchInterfaceElement, {
            externalComponents: [componentsContainer],
        });
    }
}

export interface ISearchInterfaceComponentInitializer {
    loadComponentsWithSearchInterfaceInitialization(searchInterfaceElement: HTMLElement, element: HTMLElement): void;
    loadComponentsForSearchInterface(searchInterface: SearchInterface, newComponent: HTMLElement): Promise<void>;
    tryUnloadComponentsForSearchInterface(searchInterface: SearchInterface, newComponent: HTMLElement): void;
}

const initializer = new SearchInterfaceComponentInitializer();
export function getInitializerInstance(): ISearchInterfaceComponentInitializer {
    return initializer;
}
