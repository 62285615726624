import { IFieldTypeResolver, IExternalFieldConfig, ISourceSpecificFieldConfig } from './IFieldTypeResolver';

export class SourceSpecificFieldResolver implements IFieldTypeResolver {
    private static readonly validFirstCharacters = new RegExp('[a-zA-Z_]');

    constructor(
        private sourceSpecificFields: ISourceSpecificFieldConfig[],
        private externalFields: IExternalFieldConfig[]
    ) {}

    public shouldEscapeFirstCharacter(fieldName: string): boolean {
        return !fieldName[0].match(SourceSpecificFieldResolver.validFirstCharacters);
    }

    public shouldEscapeSpecialCharacters(fieldName: string): boolean {
        return !this.externalFields.some((field) => field.fieldName.toLowerCase() === fieldName.toLowerCase());
    }

    public shouldDecorate(fieldName: string): boolean {
        return this.sourceSpecificFields.some((field) => field.fieldName.toLowerCase() === fieldName.toLowerCase());
    }

    public isExternalField(fieldName: string): boolean {
        return this.externalFields.some((field) => field.fieldName.toLowerCase() === fieldName.toLowerCase());
    }
}
