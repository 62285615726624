import { LazyInitialization, IComponentDefinition } from 'coveo-search-ui';

export function lazyCoveoSendAnalyticsToSitecore(): void {
    LazyInitialization.registerLazyComponent('SendAnalyticsToSitecore', () => {
        return new Promise((resolve, reject) => {
            require.ensure(
                ['./SendAnalyticsToSitecore'],
                () => {
                    const loaded = require<IComponentDefinition>('./SendAnalyticsToSitecore.ts')[
                        'SendAnalyticsToSitecore'
                    ];
                    resolve(loaded);
                },
                LazyInitialization.buildErrorCallback('SendAnalyticsToSitecore', resolve),
                'SendAnalyticsToSitecore'
            );
        });
    });
}
