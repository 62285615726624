export interface ICoveoLabelsHandler {
    translate(key: string): string;
}

export interface ILabelsDictionary {
    [key: string]: string;
}

export class CoveoLabelsHandler implements ICoveoLabelsHandler {
    constructor(private labels: ILabelsDictionary) {}

    public translate(key: string): string {
        return this.labels[key] || key;
    }
}
