import { ICoveoFieldHandler } from '../../handlers/CoveoFieldsHandler';

export type ISortDirection = 'ascending' | 'descending';

export class SortCriteriaResolver {
    constructor(private fieldsHandler: ICoveoFieldHandler) {}

    resolveFieldCriteria(
        fieldCriteria: string,
        sortDirection: ISortDirection,
        enableBothSortDirections: boolean,
        enableSortDropdown: boolean
    ): string {
        const field = fieldCriteria.charAt(0) !== '@' ? `@${fieldCriteria}` : fieldCriteria;
        return this.getFieldCriteria(
            this.fieldsHandler.toCoveo(field),
            sortDirection,
            enableBothSortDirections,
            enableSortDropdown
        );
    }

    resolveDateCriteria(
        sortDirection: ISortDirection,
        enableBothSortDirections: boolean,
        enableSortDropdown: boolean
    ): string {
        return this.getFieldCriteria('date', sortDirection, enableBothSortDirections, enableSortDropdown);
    }

    resolveRelevancyCriteria(): string {
        return 'relevancy';
    }

    private getFieldCriteria(
        fieldCriteria: string,
        sortDirection: ISortDirection,
        enableBothSortDirections: boolean,
        enableSortDropdown: boolean
    ): string {
        if (enableBothSortDirections && !enableSortDropdown) {
            return `${this.getFieldWithDirection(fieldCriteria, sortDirection)}, ${this.getFieldWithDirection(
                fieldCriteria,
                this.getInvertedDirection(sortDirection)
            )}`;
        } else {
            return this.getFieldWithDirection(fieldCriteria, sortDirection);
        }
    }

    private getFieldWithDirection(fieldCriteria: string, sortDirection: ISortDirection): string {
        return `${fieldCriteria} ${sortDirection}`;
    }

    private getInvertedDirection(sortDirection: ISortDirection): ISortDirection {
        return sortDirection === 'descending' ? 'ascending' : 'descending';
    }
}
