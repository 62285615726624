import { IPrebindHelper, IPrebindHelperInitializationArgs } from '../PrebindHelper';
import { IPrebindHandler } from '../PrebindResolver';

export class UnfoldDictionaryToAttributesPrebind implements IPrebindHelper {
    public name: string = 'unfoldDictionaryToAttributesPrebind';

    public getPrebind(args: IPrebindHelperInitializationArgs): IPrebindHandler {
        return (rawDictionary: string, element: HTMLElement) => {
            const parsedDictionary = JSON.parse(rawDictionary);
            Object.keys(parsedDictionary).forEach((key) => {
                const translatedField = args.fieldsHandler.toCoveo(key);
                const dataKey = 'field' + translatedField.charAt(0).toUpperCase() + translatedField.slice(1);
                element.dataset[dataKey] = parsedDictionary[key];
            });
            return rawDictionary;
        };
    }
}
