import { IComponentDefinition, LazyInitialization } from 'coveo-search-ui';

export function lazyCoveoForSitecoreAnalytics(): void {
    LazyInitialization.registerLazyComponent('ForSitecoreAnalytics', () => {
        return new Promise((resolve, reject) => {
            require.ensure(
                ['./CoveoForSitecoreAnalytics'],
                () => {
                    const loaded = require<IComponentDefinition>('./CoveoForSitecoreAnalytics.ts')[
                        'CoveoForSitecoreAnalytics'
                    ];
                    resolve(loaded);
                },
                LazyInitialization.buildErrorCallback('CoveoForSitecoreAnalytics', resolve),
                'CoveoForSitecoreAnalytics'
            );
        });
    });
}
