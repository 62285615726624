import { IPrebindHelper, IPrebindHelperInitializationArgs } from '../PrebindHelper';
import { IPrebindHandler } from '../PrebindResolver';
import {
    IItemIdValueResolver,
    ResolveIfSelector,
    ResolveIfItemIdInPage,
    UseItemIdSelectorAsIs,
} from './ItemIdSelector';

import { SearchInterface } from 'coveo-search-ui';

export class MainSearchInterfaceSelectorPrebind implements IPrebindHelper {
    public name: string = 'mainSearchInterfaceSelector';

    private resolvers: IItemIdValueResolver[] = [
        new ResolveIfSelector(),
        new ResolveIfItemIdInPage(),
        new ResolveParentSearchInterfaceId(),
        new UseItemIdSelectorAsIs(),
    ];

    public getPrebind(args: IPrebindHelperInitializationArgs): IPrebindHandler {
        return (mainSearchInterfaceId: string, element: HTMLElement) => {
            const resolver: IItemIdValueResolver = this.getFirstValidResolver(mainSearchInterfaceId, element);

            return resolver.getValue(mainSearchInterfaceId, element);
        };
    }

    private getFirstValidResolver(mainSearchInterfaceId: string, element: HTMLElement): IItemIdValueResolver {
        let resolver: IItemIdValueResolver;

        do {
            if (this.resolvers.length === 0) {
                const message =
                    'Could not resolve the search interface element using the current resolver. Ensure that a valid value is set.';
                console.error(message, {
                    mainSearchInterfaceId: mainSearchInterfaceId,
                    element: element,
                });
                throw message;
            }
            resolver = this.resolvers.shift();
        } while (!resolver.canResolve(mainSearchInterfaceId, element));

        return resolver;
    }
}

class ResolveParentSearchInterfaceId implements IItemIdValueResolver {
    private foundId: string;

    canResolve(mainSearchInterfaceId: string, element: HTMLElement): boolean {
        return this.getValue(mainSearchInterfaceId, element) !== null;
    }

    getValue(mainSearchInterfaceId: string, element: HTMLElement): string {
        if (typeof this.foundId === 'undefined') {
            this.foundId = this.tryFindParentSearchInterfaceFromElement(element);
        }

        return this.foundId;
    }

    private tryFindParentSearchInterfaceFromElement(element: HTMLElement): string {
        const parentSearchInterface = this.findParentWithClass(element, `Coveo${SearchInterface.ID}`);

        return !!parentSearchInterface ? `#${parentSearchInterface.id}` : null;
    }

    private findParentWithClass(element: HTMLElement, className: string): HTMLElement {
        let parent = element.parentElement;
        while ((parent = parent.parentElement) && !parent.classList.contains(className)) {}
        return parent;
    }
}
