import { IPrebindHelper } from './PrebindHelper';

import { AnalyticsEnabledPrebind } from './prebindHelpers/AnalyticsEnabled';
import { CoveoForSitecoreAnalyticsEndpointPrebind } from './prebindHelpers/CoveoForSitecoreAnalyticsEndpoint';
import { CurrentMaximumAge } from './prebindHelpers/CurrentMaximumAge';
import { CurrentPageNamePrebind } from './prebindHelpers/CurrentPageName';
import { FieldTranslatorPrebind } from './prebindHelpers/FieldTranslator';
import { IsAnonymousPrebind } from './prebindHelpers/IsAnonymous';
import { MainSearchInterfaceSelectorPrebind } from './prebindHelpers/MainSearchInterfaceSelector';
import { ItemIdSelectorPrebind } from './prebindHelpers/ItemIdSelector';
import { SortCriteriaPrebind } from './prebindHelpers/SortCriteria';
import { UnfoldDictionaryToAttributesPrebind } from './prebindHelpers/UnfoldDictionaryToAttributes';

export class PrebindHelpers {
    public static getAllPrebindHelpers(): IPrebindHelper[] {
        return [
            new AnalyticsEnabledPrebind(),
            new CoveoForSitecoreAnalyticsEndpointPrebind(),
            new CurrentMaximumAge(),
            new CurrentPageNamePrebind(),
            new FieldTranslatorPrebind(),
            new IsAnonymousPrebind(),
            new MainSearchInterfaceSelectorPrebind(),
            new ItemIdSelectorPrebind(),
            new SortCriteriaPrebind(),
            new UnfoldDictionaryToAttributesPrebind(),
        ];
    }
}
