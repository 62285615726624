import { IPrebindHelper, IPrebindHelperInitializationArgs } from '../PrebindHelper';
import { IPrebindHandler } from '../PrebindResolver';

export class CurrentPageNamePrebind implements IPrebindHelper {
    public name: string = 'currentPageName';

    public getPrebind(args: IPrebindHelperInitializationArgs): IPrebindHandler {
        return (pageName: string, element: HTMLElement) => {
            return pageName || args.context.scPageName;
        };
    }
}
