import { Component } from 'coveo-search-ui';

import { nodeListToArray } from './HTMLUtils';

export class SearchInterfacesProvider {
    getSearchInterfacesInPage(componentsIds: string[]): HTMLElement[] {
        const searchInterfacesSelector = this.getSearchInterfacesSelectorFromComponentIds(componentsIds);
        const searchInterfaces = document.querySelectorAll(searchInterfacesSelector);
        return nodeListToArray(searchInterfaces);
    }

    private getSearchInterfacesSelectorFromComponentIds(componentIds: string[]): string {
        return componentIds.map((componentId) => Component.computeSelectorForType(componentId)).join(', ');
    }
}
