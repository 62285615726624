import { IPrebindHelper, IPrebindHelperInitializationArgs } from '../PrebindHelper';
import { IPrebindHandler } from '../PrebindResolver';
import { ISortDirection, SortCriteriaResolver } from '../../sortCriteria/SortCriteriaResolver';

export class SortCriteriaPrebind implements IPrebindHelper {
    public name: string = 'sortCriteriaResolver';

    public getPrebind(args: IPrebindHelperInitializationArgs): IPrebindHandler {
        const criteriaResolver = new SortCriteriaResolver(args.fieldsHandler);
        return (sortCriteriaRawValue: string, element: HTMLElement) => {
            const sortCriteria = JSON.parse(sortCriteriaRawValue);
            if (Object.keys(sortCriteria).length === 0) {
                return null;
            }
            const sortType = (sortCriteria.type || 'relevancy').toLowerCase();
            const sortDirection = (sortCriteria.direction || 'ascending').toLowerCase() as ISortDirection;
            const enableBothSortDirections =
                (element.dataset['scEnableBothSortDirections'] || 'false').toLowerCase() === 'true';
            const sortDropdownElement = document.querySelector('.coveo-sort-section .CoveoSortDropdown');
            const enableSortDropdown = document.body.contains(sortDropdownElement);

            if (sortType === 'relevancy') {
                return criteriaResolver.resolveRelevancyCriteria();
            } else if (sortType === 'date') {
                return criteriaResolver.resolveDateCriteria(
                    sortDirection,
                    enableBothSortDirections,
                    enableSortDropdown
                );
            } else {
                const sortField = sortCriteria.field;
                return criteriaResolver.resolveFieldCriteria(
                    sortField,
                    sortDirection,
                    enableBothSortDirections,
                    enableSortDropdown
                );
            }
        };
    }
}
