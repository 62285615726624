import { IComponentDefinition, LazyInitialization } from 'coveo-search-ui';

export function lazyCoveoForSitecoreExternalContent(): void {
    LazyInitialization.registerLazyComponent('ForSitecoreExternalContent', () => {
        return new Promise((resolve, reject) => {
            require.ensure(
                ['./CoveoForSitecoreExternalContent'],
                () => {
                    const loaded = require<IComponentDefinition>('./CoveoForSitecoreExternalContent.ts')[
                        'CoveoForSitecoreExternalContent'
                    ];
                    resolve(loaded);
                },
                LazyInitialization.buildErrorCallback('CoveoForSitecoreExternalContent', resolve),
                'CoveoForSitecoreExternalContent'
            );
        });
    });
}
