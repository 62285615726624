import { ExpressionBuilder } from 'coveo-search-ui';

export interface IBuildingExternalContentEventArgs {
    sources: string[];
}

export interface IBuildingLocalSitecoreInstanceExpressionEventArgs {
    expression: ExpressionBuilder;
    externalSources: string[];
    buildingQueryAlreadyProcessed: boolean;
}

export class SitecoreExpressionEvents {
    public static onBuildingSitecoreExternalContent = 'onBuildingSitecoreExternalContent';
    public static onBuildingLocalSitecoreInstanceExpression = 'onBuildingLocalSitecoreInstanceExpression';
}
