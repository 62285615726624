import { IPrebindHelper, IPrebindHelperInitializationArgs } from '../PrebindHelper';
import { IPrebindHandler } from '../PrebindResolver';

export class FieldTranslatorPrebind implements IPrebindHelper {
    public name: string = 'fieldTranslator';

    public getPrebind(args: IPrebindHelperInitializationArgs): IPrebindHandler {
        return (name: string, element: HTMLElement) => {
            return args.fieldsHandler.toCoveo(name);
        };
    }
}
