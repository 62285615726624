import { IExternalFieldConfig } from './CoveoFieldsHandler';
import { IFieldTypeResolver } from './IFieldTypeResolver';

export class LegacyFieldTypeResolver implements IFieldTypeResolver {
    private readonly externalFields: IExternalFieldConfig[];

    constructor(externalFields: IExternalFieldConfig[]) {
        this.externalFields = this.parseExternalFields(externalFields);
    }

    private parseExternalFields(externalFields: Array<string | IExternalFieldConfig>): IExternalFieldConfig[] {
        return externalFields.map((externalField) => {
            if (typeof externalField === 'string') {
                return {
                    fieldName: (externalField as string).toLowerCase(),
                    shouldEscape: false,
                };
            } else if (this.isExternalFieldConfig(externalField)) {
                return {
                    fieldName: externalField.fieldName.toLowerCase(),
                    shouldEscape: externalField.shouldEscape,
                };
            } else {
                console.error(`Invalid external field '${externalField}'.`);
            }
        });
    }

    public shouldEscapeFirstCharacter(fieldName: string): boolean {
        return false;
    }

    public shouldEscapeSpecialCharacters(fieldName: string): boolean {
        const fields = this.externalFields.filter((field) => field.fieldName.toLowerCase() === fieldName.toLowerCase());

        return fields.length > 0 ? fields[0].shouldEscape : true;
    }

    public shouldDecorate(fieldName: string): boolean {
        return !this.externalFields.some((field) => field.fieldName.toLowerCase() === fieldName.toLowerCase());
    }

    public isExternalField(fieldName: string): boolean {
        return this.externalFields.some((field) => field.fieldName.toLowerCase() === fieldName.toLowerCase());
    }

    private isExternalFieldConfig(obj: any): boolean {
        return (
            typeof obj !== 'undefined' &&
            typeof obj.fieldName !== 'undefined' &&
            typeof obj.shouldEscape !== 'undefined'
        );
    }
}
