import { IComponentDefinition, LazyInitialization } from 'coveo-search-ui';

export function lazyCoveoGoogleApiPositionProvider(): void {
    LazyInitialization.registerLazyComponent('GoogleApiPositionProvider', () => {
        return new Promise((resolve, reject) => {
            require.ensure(
                ['./GoogleApiPositionProvider'],
                () => {
                    const loaded = require<IComponentDefinition>('./GoogleApiPositionProvider.ts')[
                        'GoogleApiPositionProvider'
                    ];
                    resolve(loaded);
                },
                LazyInitialization.buildErrorCallback('GoogleApiPositionProvider', resolve),
                'GoogleApiPositionProvider'
            );
        });
    });
}
